
import { getPromopterInfoCache, setPromopterInfoCache, getVisitorCommonInfoCache, setVisitorCommonInfoCache, setVisitorPrivateInfoCacheByPromoterId } from '@/plugins/cookie'
import { getUserInfo } from '@/api/common.js'

const state = {

    visitorCommonInfo: getVisitorCommonInfoCache(),
    visitorPrivateInfo: {},
    visitor: null,//  访客

    promopterInfo: getPromopterInfoCache(),
    promopter: null,
    c: '',
    historyId: '',
    shareLink: '',
    entityId: '',
    entityType: '',
    creId: '',
    title: '',
    visitLink: '',
    visitTitle: '',
    ai: {
        c: '',
        p: '',
        uuid: '',
        vshId: '',
    },
}

const mutations = {
    CHNAGE_VISIT_LINK(state, data) {
        state.visitLink = data
    },
    CHNAGE_VISIT_TITLE(state, data) {
        state.visitTitle = data
    },
    CHNAGE_PROMOPTER_INFO(state, data) {
        // 获取 data 的自身可枚举属性
        const dataPropertyNames = Object.getOwnPropertyNames(data);

        // 遍历 dataPropertyNames
        for (const propertyName of dataPropertyNames) {
            // 如果 promopterInfo 中也有相同的属性
            if (propertyName in state.promopterInfo) {
                // 更新 promopterInfo 对应属性的值
                state.promopterInfo[propertyName] = data[propertyName];
            }
        }

        // 保存更新后的 promopterInfo 到缓存
        setPromopterInfoCache(state.promopterInfo);


    },
    CHNAGE_VISITOR_COMMON_INFO(state, data) {
        // 获取 data 的自身可枚举属性
        const dataPropertyNames = Object.getOwnPropertyNames(data);

        // 遍历 dataPropertyNames
        for (const propertyName of dataPropertyNames) {

            // 更新 visitorCommonInfo 对应属性的值
            state.visitorCommonInfo[propertyName] = data[propertyName];
        }

        // 保存更新后的 visitorCommonInfo 到缓存
        setVisitorCommonInfoCache(state.visitorCommonInfo);


    },
    CHNAGE_VISITOR_PRIVATE_INFO(state, data) {
        state.visitorPrivateInfo = data;

        // 保存更新后的 visitorPrivateInfo 到缓存
        setVisitorPrivateInfoCacheByPromoterId(data.userName, data);


    },
    CHNAGE_VISITOR(state, data) {
        state.visitor = data;
    },
    CHNAGE_PROMOPTER(state, data) {

        state.promopter = data;
    },
    CHANGE_CRE_ID(state, value) {
        state.creId = value
    },
    CHANGE_TITLE(state, value) {
        state.title = value
    },
    CHANGE_ENTITY_ID(state, value) {
        state.entityId = value
    },
    CHANGE_ENTITY_TYPE(state, value) {
        state.entityType = value
    },
    CHANGE_SHARE_LINK(state, value) {
        state.shareLink = value
    },
    change_c(state, value) {
        state.c = value
    },
    change_ai(state, value) {
        state.ai = value
    },
    CHANGE_HISTORY_ID(state, value) {
        state.historyId = value
    },
}

const actions = {
    // 这个是用户配置中的promopterinfo
    updatePromopterInfo(context) {
        return getUserInfo().then(res => {
            // 对接微信授权的头像字段

            context.commit('CHNAGE_PROMOPTER_INFO', res)
            return res;

        })
    }

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}

