export const aritcleRoute = [
    {
        path: 'article',
        meta: {
            isShowMenu: false,
            auth: true,
            isDefaultWxInit: true,
            keepAlive: true,
            title:'获客文章'
           
        },
        component: () => import('@/pages/article/index.vue')
    },
    {
        path: 'article/detail/:id',
        name: 'articleDetail',
        meta: {
            isShowMenu: false,
            auth: false,
            keepAlive: false,
            title: ''
        },
        component: () => import('@/pages/article/detail.vue')
    }
]