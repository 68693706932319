export const chatRoute = [

    {
        path: 'chatIndex',
        name: 'chatIndex',
        meta: {
            isShowMenu: false,
            auth: false,
            isDefaultWxInit: true,
            keepAlive: false,
            title:'业之峰AI实验室'
           
        },
        component: () => import('@/pages/chat/chatIndex.vue')
    },
    {
        path: 'chatCover',
        name: 'chatCover',
        meta: {
            isShowMenu: false,
            auth: false,
            isDefaultWxInit: true,
            keepAlive: false,
            title:'业之峰AI实验室'
           
        },
        component: () => import('@/pages/chat/chatCover.vue')
    },
    {
        path: 'chatDetail',
        name: 'chatDetail',
        meta: {
            isShowMenu: false,
            auth: false,
            keepAlive: false,
            title: '业之峰AI实验室'
        },
        component: () => import('@/pages/chat/chatDetail.vue')
    }
]