export const emoj = [

    {
        "name": "[微笑]",
        "src": require("@/assets/img/message/emoj/001.png")
    },
    {
        "name": "[害羞]",
        "src": require("@/assets/img/message/emoj/002.png")
    },
    {
        "name": "[吐舌头]",
        "src": require("@/assets/img/message/emoj/003.png")
    },
    {
        "name": "[偷笑]",
        "src": require("@/assets/img/message/emoj/004.png")
    },
    {
        "name": "[爱慕]",
        "src": require("@/assets/img/message/emoj/005.png")
    },
    {
        "name": "[大笑]",
        "src": require("@/assets/img/message/emoj/006.png")
    },
    {
        "name": "[跳舞]",
        "src": require("@/assets/img/message/emoj/007.png")
    },
    {
        "name": "[飞吻]",
        "src": require("@/assets/img/message/emoj/008.png")
    },
    {
        "name": "[安慰]",
        "src": require("@/assets/img/message/emoj/009.png")
    },
    {
        "name": "[抱抱]",
        "src": require("@/assets/img/message/emoj/010.png")
    },
    {
        "name": "[加油]",
        "src": require("@/assets/img/message/emoj/011.png")
    },
    {
        "name": "[胜利]",
        "src": require("@/assets/img/message/emoj/012.png")
    },
    {
        "name": "[强]",
        "src": require("@/assets/img/message/emoj/013.png")
    },
    {
        "name": "[亲亲]",
        "src": require("@/assets/img/message/emoj/014.png")
    },
    {
        "name": "[花痴]",
        "src": require("@/assets/img/message/emoj/015.png")
    },
    {
        "name": "[露齿笑]",
        "src": require("@/assets/img/message/emoj/016.png")
    },
    {
        "name": "[查找]",
        "src": require("@/assets/img/message/emoj/017.png")
    },
    {
        "name": "[呼叫]",
        "src": require("@/assets/img/message/emoj/018.png")
    },
    {
        "name": "[算账]",
        "src": require("@/assets/img/message/emoj/019.png")
    },
    {
        "name": "[财迷]",
        "src": require("@/assets/img/message/emoj/020.png")
    },
    {
        "name": "[好主意]",
        "src": require("@/assets/img/message/emoj/021.png")
    },
    {
        "name": "[鬼脸]",
        "src": require("@/assets/img/message/emoj/022.png")
    },
    {
        "name": "[天使]",
        "src": require("@/assets/img/message/emoj/023.png")
    },
    {
        "name": "[再见]",
        "src": require("@/assets/img/message/emoj/024.png")
    },
    {
        "name": "[流口水]",
        "src": require("@/assets/img/message/emoj/025.png")
    },
    {
        "name": "[享受]",
        "src": require("@/assets/img/message/emoj/026.png")
    },
    {
        "name": "[色情狂]",
        "src": require("@/assets/img/message/emoj/027.png")
    },
    {
        "name": "[呆若木鸡]",
        "src": require("@/assets/img/message/emoj/028.png")
    },
    {
        "name": "[思考]",
        "src": require("@/assets/img/message/emoj/029.png")
    },
    {
        "name": "[迷惑]",
        "src": require("@/assets/img/message/emoj/030.png")
    },
    {
        "name": "[疑问]",
        "src": require("@/assets/img/message/emoj/031.png")
    },
    {
        "name": "[没钱了]",
        "src": require("@/assets/img/message/emoj/032.png")
    },
    {
        "name": "[无聊]",
        "src": require("@/assets/img/message/emoj/033.png")
    },
    {
        "name": "[怀疑]",
        "src": require("@/assets/img/message/emoj/034.png")
    },
    {
        "name": "[嘘]",
        "src": require("@/assets/img/message/emoj/035.png")
    },
    {
        "name": "[小样]",
        "src": require("@/assets/img/message/emoj/036.png")
    },
    {
        "name": "[摇头]",
        "src": require("@/assets/img/message/emoj/037.png")
    },
    {
        "name": "[感冒]",
        "src": require("@/assets/img/message/emoj/038.png")
    },
    {
        "name": "[尴尬]",
        "src": require("@/assets/img/message/emoj/039.png")
    },
    {
        "name": "[傻笑]",
        "src": require("@/assets/img/message/emoj/040.png")
    },
    {
        "name": "[不会吧]",
        "src": require("@/assets/img/message/emoj/041.png")
    },
    {
        "name": "[无奈]",
        "src": require("@/assets/img/message/emoj/042.png")
    },
    {
        "name": "[流汗]",
        "src": require("@/assets/img/message/emoj/043.png")
    },
    {
        "name": "[凄凉]",
        "src": require("@/assets/img/message/emoj/044.png")
    },
    {
        "name": "[困了]",
        "src": require("@/assets/img/message/emoj/045.png")
    },
    {
        "name": "[晕]",
        "src": require("@/assets/img/message/emoj/046.png")
    },
    {
        "name": "[忧伤]",
        "src": require("@/assets/img/message/emoj/047.png")
    },
    {
        "name": "[委屈]",
        "src": require("@/assets/img/message/emoj/048.png")
    },
    {
        "name": "[悲泣]",
        "src": require("@/assets/img/message/emoj/049.png")
    },
    {
        "name": "[大哭]",
        "src": require("@/assets/img/message/emoj/050.png")
    },
    {
        "name": "[痛哭]",
        "src": require("@/assets/img/message/emoj/051.png")
    },
    {
        "name": "[I服了U]",
        "src": require("@/assets/img/message/emoj/052.png")
    },
    {
        "name": "[对不起]",
        "src": require("@/assets/img/message/emoj/053.png")
    },
    {
        "name": "[挥泪告别]",
        "src": require("@/assets/img/message/emoj/054.png")
    },
    {
        "name": "[皱眉]",
        "src": require("@/assets/img/message/emoj/055.png")
    },
    {
        "name": "[好累]",
        "src": require("@/assets/img/message/emoj/056.png")
    },
    {
        "name": "[吐]",
        "src": require("@/assets/img/message/emoj/058.png")
    },
    {
        "name": "[背]",
        "src": require("@/assets/img/message/emoj/059.png")
    },
    {
        "name": "[惊讶]",
        "src": require("@/assets/img/message/emoj/060.png")
    },
    {
        "name": "[惊愕]",
        "src": require("@/assets/img/message/emoj/061.png")
    },
    {
        "name": "[闭嘴]",
        "src": require("@/assets/img/message/emoj/062.png")
    },
    {
        "name": "[欠扁]",
        "src": require("@/assets/img/message/emoj/063.png")
    },
    {
        "name": "[鄙视你]",
        "src": require("@/assets/img/message/emoj/064.png")
    },
    {
        "name": "[大怒]",
        "src": require("@/assets/img/message/emoj/065.png")
    },
    {
        "name": "[生气]",
        "src": require("@/assets/img/message/emoj/066.png")
    },
    {
        "name": "[财神]",
        "src": require("@/assets/img/message/emoj/067.png")
    },
    {
        "name": "[学习雷锋]",
        "src": require("@/assets/img/message/emoj/068.png")
    },
    {
        "name": "[恭喜发财]",
        "src": require("@/assets/img/message/emoj/069.png")
    },
    {
        "name": "[小二]",
        "src": require("@/assets/img/message/emoj/070.png")
    },
    {
        "name": "[老大]",
        "src": require("@/assets/img/message/emoj/071.png")
    },
    {
        "name": "[邪恶]",
        "src": require("@/assets/img/message/emoj/072.png")
    },
    {
        "name": "[单挑]",
        "src": require("@/assets/img/message/emoj/073.png")
    },
    {
        "name": "[CS]",
        "src": require("@/assets/img/message/emoj/074.png")
    },
    {
        "name": "[隐形人]",
        "src": require("@/assets/img/message/emoj/075.png")
    },
    {
        "name": "[炸弹]",
        "src": require("@/assets/img/message/emoj/076.png")
    },
    {
        "name": "[惊声尖叫]",
        "src": require("@/assets/img/message/emoj/077.png")
    },
    {
        "name": "[漂亮MM]",
        "src": require("@/assets/img/message/emoj/078.png")
    },
    {
        "name": "[帅哥]",
        "src": require("@/assets/img/message/emoj/079.png")
    },
    {
        "name": "[招财猫]",
        "src": require("@/assets/img/message/emoj/080.png")
    },
    {
        "name": "[成交]",
        "src": require("@/assets/img/message/emoj/081.png")
    },
    {
        "name": "[鼓掌]",
        "src": require("@/assets/img/message/emoj/082.png")
    },
    {
        "name": "[握手]",
        "src": require("@/assets/img/message/emoj/083.png")
    },
    {
        "name": "[红唇]",
        "src": require("@/assets/img/message/emoj/084.png")
    },
    {
        "name": "[玫瑰]",
        "src": require("@/assets/img/message/emoj/085.png")
    },
    {
        "name": "[残花]",
        "src": require("@/assets/img/message/emoj/086.png")
    },
    {
        "name": "[爱心]",
        "src": require("@/assets/img/message/emoj/087.png")
    },
    {
        "name": "[心碎]",
        "src": require("@/assets/img/message/emoj/088.png")
    },
    {
        "name": "[钱]",
        "src": require("@/assets/img/message/emoj/089.png")
    },
    {
        "name": "[购物]",
        "src": require("@/assets/img/message/emoj/090.png")
    },
    {
        "name": "[礼物]",
        "src": require("@/assets/img/message/emoj/091.png")
    },
    {
        "name": "[收邮件]",
        "src": require("@/assets/img/message/emoj/092.png")
    },
    {
        "name": "[电话]",
        "src": require("@/assets/img/message/emoj/093.png")
    },
    {
        "name": "[举杯庆祝]",
        "src": require("@/assets/img/message/emoj/094.png")
    },
    {
        "name": "[时钟]",
        "src": require("@/assets/img/message/emoj/095.png")
    },
    {
        "name": "[等待]",
        "src": require("@/assets/img/message/emoj/096.png")
    },
    {
        "name": "[很晚了]",
        "src": require("@/assets/img/message/emoj/097.png")
    },
    {
        "name": "[飞机]",
        "src": require("@/assets/img/message/emoj/098.png")
    }
]
export const convertSteps = [{
    text: "新商机",
    value: 0,
}, {
    text: "需求确认",
    value: 1,
}, {
    text: "拜访客户",
    value: 2,
}, {
    text: "方案报价",
    value: 3,
}, {
    text: "谈判审核",
    value: 4,
}, {
    text: "商机成交",
    value: 5,
}, {
    text: "商机流失",
    value: 6,
}]
export const ClueStageTree = [{
    text: "有效",
    value: true,
    children: [{
        text: "有意向",
        value: 0
    },
    {
        text: "继续回访",
        value: 1
    },
    {
        text: "重点跟进",
        value: 2
    }
    ]
},
{
    text: "无效",
    value: false,
    children: [{
        text: "无意向",
        value: 3
    },
    {
        text: "异地",
        value: 4
    },
    {
        text: "恶意报名",
        value: 5
    },
    {
        text: "无房装修",
        value: 6
    },
    {
        text: "无购买力",
        value: 7
    },
    {
        text: "非本人",
        value: 8
    },
    {
        text: "重复",
        value: 9
    },
    {
        text: "关机/停机/未接听",
        value: 10
    },
    {
        text: "空号",
        value: 11
    },
    {
        text: "未知",
        value: 12
    }
    ]
}
]
export const operateType = {
    0: {
        text: '转发朋友圈'
    },
    1: {
        text: '转发好友'
    },
    2: {
        text: '复制链接'
    },
    3: {
        text: '海报'
    },
    4: {
        text: '收藏'
    },
    5: {
        text: '编辑'
    },
    6: {
        text: '登录'
    },
    7: {
        text: '创建'
    },
    8: {
        text: '推广员自己浏览'
    },
}
export const flowChannel = {
    0: {
        text: '朋友圈'
    },
    1: {
        text: '微信群'
    },
    2: {
        text: '微信好友'
    },
    3: {
        text: '直接访问'
    },
    4: {
        text: '后台导入'
    }
}
export const flowSource = {
    0: {
        text: '好友裂变'
    },
    1: {
        text: '我的分享'
    },
    2: {
        text: '后台导入'
    }

}
export const clientHdlType = {
    0: {
        text: '转发朋友圈',
        subText: '转发链接到朋友圈',
        historyText: '转发了该链接到朋友圈'
    },
    1: {
        text: '转发好友',
        subText: '转发链接给好友',
        historyText: '转发了该链接给好友'
    },
    2: {
        text: '浏览',
        subText: '浏览了该链接',
        historyText: '浏览了该链接'
    },
    3: {
        text: '咨询',
        subText: '进行了咨询',
        historyText: '点击咨询'

    },
    4: {
        text: '拨号',
        subText: '进行了拨号',
        historyText: '点击拨号'
    },
    5: {
        text: '报名',
        subText: '进行了报名',
        historyText: '报名成功'
    }

}
export const decorationType = {
    0: {
        text: '全包'
    },
    1: {
        text: '半包'
    },


}
export const houseSituation = {
    0: {
        text: '毛坯现房'
    },
    1: {
        text: '毛坯期房'
    },
    2: {
        text: '老房整改翻新'
    },
    3: {
        text: '老房局部改造'
    },
    4: {
        text: '别墅'
    },
    5: {
        text: '工装'
    },
    6: {
        text: '软装'
    },
    7: {
        text: '精装房'
    },
    8: {
        text: '不详'
    }

}
export const followResult = {
    0: {
        text: '有意向'
    },
    1: {
        text: '继续回访'
    },
    2: {
        text: '重点跟进'
    },
    3: {
        text: '无意向'
    },
    4: {
        text: '异地'
    },
    5: {
        text: '恶意报名'
    },
    6: {
        text: '无房装修'
    },
    7: {
        text: '无购买力'
    },
    8: {
        text: '非本人'
    },
    9: {
        text: '重复'
    },
    10: {
        text: '关机停机未接听'
    },
    11: {
        text: '空号'
    },
    12: {
        text: '未知'
    },


}
export const buopStage = {
    0: {
        text: '新商机'
    },
    1: {
        text: '需求确认'
    },
    2: {
        text: '拜访客户'
    },
    3: {
        text: '方案报价'
    },
    4: {
        text: '谈判审核'
    },
    5: {
        text: '商机成交'
    },
    6: {
        text: '商机流失'
    },



}
export const decorationTime = {
    0: {
        text: '一周内'
    },
    1: {
        text: '一个月内'
    },
    2: {
        text: '三个月内'
    },
    3: {
        text: '半年内'
    },
    4: {
        text: '一年内'
    }

}
export const auditStatus = {
    0: {
        text: '待审核',
    },
    10: {
        text: '未通过'
    },
    20: {
        text: '审核通过'
    }
}
export const chatType = {
    0: {
        text: '对话',
    },
    1: {
        text: '主动发起对话'
    },
    14: {
        text: '客户正在输入'
    },
    15: {
        text: '客户上线'
    },
    16: {
        text: '客户下线'
    }

}
export const notificationType = {
    0: {
        text: '访客来访提醒',
    },
    1: {
        text: '新报名提醒'
    },
    2: {
        text: '新对话提醒'
    },
    3: {
        text: '作品推送提醒'
    },
    4: {
        text: '点赞提醒'
    },
    5: {
        text: '软文推送提醒'
    },
    6: {
        text: '全景推送提醒'
    },
    7: {
        text: '专题推送提醒'
    }

}
